<template>
  <section class="container">
    <layout-breadcrumb :items="breadcrumbs"></layout-breadcrumb>
      <!-- <h3 class="text-transparencia  mb-3">
        <a @click="$router.go(-1)"><i class="fa fa-arrow-left cr-po mr-5" data-toggle="tooltip" data-placement="top" title="Volver"></i>Volver</a>
      </h3> -->
      <div class="row">
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-4"></div>
        <div class="col-md-4 mb-3">
          <button class="btn btn-transparencia btn-block  transition-hover px-3 rounded-pill">COORDINADORES REGIONALES</button>
        </div>
        <div class="col-lg-4 col-md-4 col-xs-12 col-sm-4"></div>
      </div>
      <h4 class="text-transparencia mt-4 mb-4 fw-600 text-underline">Lima metropolitana</h4>
      <div class="row">
        <template v-for="(coordinador, index) in coordinadores">
          <div class="col-md-4 mt-2" v-if="coordinador.esLima" :key="index">
            <div class="card mb-3 border-0" style="background-color:transparent !important">
              <div class="media mb-3 fw-400">
                <div class="media-body">
                  <span class="d-block fs-18 mb-2 text-dark fw-600">{{ coordinador.provincia }}</span>
                  <small class="d-block fs-15 text-transparencia fw-600">{{ coordinador.nombreCompleto }} </small>
                  <small class="d-block fs-15 text-transparencia">{{ coordinador.correo }}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <h4 class="text-transparencia mt-4 mb-4 fw-600 text-underline">Regiones</h4>
      <div class="row">
        <template v-for="(coordinador, index) in coordinadores">
          <div class="col-md-4 mt-2" v-if="!coordinador.esLima" :key="index">
            <div class="card mb-3 border-0" style="background-color:transparent  !important">
              <div class="media mb-3 fw-400">
                <div class="media-body">
                  <span class="d-block fs-18 mb-2 text-dark fw-600">{{ coordinador.provincia }}</span>
                  <small class="d-block fs-15 text-transparencia fw-600">{{ coordinador.nombreCompleto }} </small>
                  <small class="d-block fs-15 text-transparencia">{{ coordinador.correo }}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
  </section>
</template>

<script>
import layoutBreadcrumb from "@/layout/layout-breadcrumb";
export default {
  name: "view-coordinadores",
  metaInfo: {
    title: "Coordinadores",
  },
  data: () => ({
    coordinadores: [],
  }),
  components: {layoutBreadcrumb},
  methods: {
    fetchDatos: async function() {
      await this.$http.get(this.apiRoute("Common", "ListVoluntariadoCoordinadoresAll")).then(function(response) {
        if (!response.body.error) {
          this.coordinadores = response.body.data;
        }
      });
    },
  },
  mounted: async function() {
    await this.fetchDatos();

    this.breadcrumbs = [
      { name: "Inicio", url: "/home", icon: "fa fa-home", active: false },
      { name: "Voluntariado", url: "/voluntariado", icon: "", active: false },
      { name: "Coordinadores", url: "", icon: "", active: true },
    ];
  },
};
</script>
